<template>
    <v-container>
        <v-row>
            <v-col cols="6">
                <v-card flat>
                    <v-card-title>
                        <v-icon>mdi-calendar-clock</v-icon>
                        <span>Sorggruppe typer</span>
                    </v-card-title>
                    <v-card-text class="text--primary">
                        <p>Se oversikt og rediger ulike sorggruppe typer.</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn to="/sorg/administrer/gruppetyper/" outlined>Rediger</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="6">
                <v-card flat>
                    <v-card-title>
                        <v-icon>mdi-calendar-clock</v-icon>
                        <span>Sorggruppe metoder</span>
                    </v-card-title>
                    <v-card-text class="text--primary">
                        <p>Se oversikt og rediger ulike sorggruppe metoder.</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn to="/sorg/administrer/gruppemetoder/" outlined>Rediger</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'GriefSettings',
};
</script>
